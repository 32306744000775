import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1 style={{marginBottom: 20}}>That page can’t be found.</h1>
    <p>It looks like nothing was found at this location.</p>
  </Layout>
)

export default NotFoundPage
